/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { spanStyles, focalSmallStyles } from 'typography';

const tagSizes = {
    small: css`
        min-height: 24px;
        padding: ${({ theme }) => `${theme.spacing[0.5]} ${theme.spacing[2]}`};
    `,
    medium: css`
        min-height: 36px;
        padding: ${({ theme }) => `${theme.spacing[0.5]} ${theme.spacing[2]}`};
    `,
    large: css`
        ${spanStyles}
        min-height: 52px;
        padding: ${({ theme }) => `${theme.spacing[1.5]} ${theme.spacing[3.5]}`};

        @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
            ${focalSmallStyles}
            min-height: 64px;
            padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};
        }
    `,
};

const TagStyled = styled.span`
    display: inline-flex;
    margin: 0;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    white-space: nowrap;

    ${({ size }) => tagSizes[size]}

    & a {
        color: ${({ theme }) => theme.palette.primaryRegular};
        text-decoration: none;

        &:active,
        &:hover {
            text-decoration: underline;
        }
    }
`;

export { TagStyled };
