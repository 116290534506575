import styled, { css } from 'styled-components';
import { TagStyled } from 'components/Tag/Tag.style';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    ${({ isStacked }) =>
        isStacked
            ? css`
                  flex-wrap: wrap;
              `
            : css`
                  flex-wrap: nowrap;
                  overflow-x: auto;

                  @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
                      flex-wrap: wrap;
                  }
              `};

    // NOTE: Here I need to just style the tag component from
    // styled-components, so was necessary to import the actual
    // component and target that way.
    & > ${TagStyled} {
        margin-bottom: 0.5rem;

        &:not(:last-child) {
            margin-right: 0.5rem;
        }
    }
`;
