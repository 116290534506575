/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, ComponentPropsWithRef } from 'react';
import { Wrapper } from './Tags.style';

const Tags = forwardRef(({ isStacked, children, ...rest }, ref) => (
    <Wrapper ref={ref} isStacked={isStacked} {...rest}>
        {children}
    </Wrapper>
));

Tags.defaultProps = {
    isStacked: false,
};

export default Tags;
