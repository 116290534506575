/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, ComponentPropsWithRef } from 'react';
import { TagStyled } from './Tag.style';

const Tag = forwardRef(({ size, children, ...rest }, ref) => (
    <TagStyled ref={ref} size={size} {...rest}>
        {children}
    </TagStyled>
));

Tag.defaultProps = {
    size: 'medium',
};

export default Tag;
